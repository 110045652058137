import { useState, useEffect } from "react";
// Redux
import { useSelector } from "react-redux";
// react-router components
import { Link } from "react-router-dom";
// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";
// @mui core components
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Icon from "@mui/material/Icon";
import Badge from "@mui/material/Badge";
import { styled } from "@mui/material/styles";
// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftAvatar from "components/SoftAvatar";
// Custom styles for DashboardNavbar
import {
  navbar,
  navbarContainer,
} from "examples/Navbars/DashboardNavbar/styles";
// Soft UI Dashboard PRO React context
import {
  useSoftUIController,
  setTransparentNavbar,
  setMiniSidenav,
} from "context";
// Routes
import { ROUTES } from "constants";
// Utils
import { stringAvatar } from "utils/stringAvatar";
import useAllWalletStatus from "utils/useAllWalletStatus";
// Components
import NotificationsBell from "./components/NotificationsBell";
import Toast from "../../../componentsCustom/Toast";
import FlagIcon from "./components/FlagIcon";
import LanguageMenu from "./components/LanguageMenu";
import Loader from "components/Loader";
// Translate
import { useTranslation } from "react-i18next";
// Constants
import { STATUS } from "constants/fetchStatus";
import clientConfig from "utils/clientConfig";
import { useIsUserFreemium, useIsUserPremium } from "utils/useUserSubscriptionStatus";

function DashboardNavbar({ absolute, light, isMini }) {
  const { t } = useTranslation();
  const [openMenu, setOpenMenu] = useState(false);
  const handleOpenMenu = (event) => setOpenMenu(event.currentTarget);
  const handleCloseMenu = () => setOpenMenu(false);
  const balance = useSelector(
    (state) => state.wallets?.all?.walletBaseCoinValue
  );
  const { allWalletStatus } = useAllWalletStatus();
  const notifications = useSelector((state) => state.notifications);
  const { fullName, subscription_status, role } = useSelector(
    (state) => state.currentUser.data
  );
  const isUserFreemium = useIsUserFreemium();
  const isUserPremium = useIsUserPremium();
  const [navbarType, setNavbarType] = useState();
  const [controller, dispatch] = useSoftUIController();
  const { miniSidenav, transparentNavbar, fixedNavbar } = controller;

  // LanguageMenu State
  const [anchorEl, setAnchorEl] = useState(null);
  const openLanguages = Boolean(anchorEl);
  const handleOpenLanguages = (event) => setAnchorEl(event.currentTarget);
  const handleCloseLanguages = () => setAnchorEl(null);

  const NotificationDot = styled(Badge)(({ theme }) => ({
    "& .MuiBadge-badge": {
      backgroundColor: "#21d4fd",
      color: "#2152ff",
      boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
      "&::after": {
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        borderRadius: "50%",
        animation: "ripple 1.2s infinite ease-in-out",
        border: "1px solid currentColor",
        content: '""',
      },
    },
    "@keyframes ripple": {
      "0%": {
        transform: "scale(.8)",
        opacity: 1,
      },
      "100%": {
        transform: "scale(2.4)",
        opacity: 0,
      },
    },
  }));

  useEffect(() => {
    // Setting the navbar type
    if (fixedNavbar) {
      setNavbarType("sticky");
    } else {
      setNavbarType("static");
    }

    // A function that sets the transparent state of the navbar.
    function handleTransparentNavbar() {
      setTransparentNavbar(
        dispatch,
        (fixedNavbar && window.scrollY === 0) || !fixedNavbar
      );
    }

    /** 
     The event listener that's calling the handleTransparentNavbar function when 
     scrolling the window.
    */
    window.addEventListener("scroll", handleTransparentNavbar);

    // Call the handleTransparentNavbar function to set the state with the initial value.
    handleTransparentNavbar();

    // Remove event listener on cleanup
    return () => window.removeEventListener("scroll", handleTransparentNavbar);
  }, [dispatch, fixedNavbar]);

  const handleMiniSidenav = () => setMiniSidenav(dispatch, !miniSidenav);
  const someNotificationUnread = notifications.some(
    (notification) => !notification.read
  );

  return (
    <AppBar
      position={absolute ? "absolute" : navbarType}
      color="inherit"
      sx={(theme) => navbar(theme, { transparentNavbar, absolute, light })}
    >
      <Toast />
      <Toolbar sx={(theme) => navbarContainer(theme)}>
        {miniSidenav && (
          <SoftBox color={light ? "white" : "inherit"}>
            <IconButton color="inherit" onClick={handleMiniSidenav}>
              <Icon className={light ? "text-white" : "text-dark"}>menu</Icon>
            </IconButton>
          </SoftBox>
        )}
        <SoftBox>
          <SoftBox
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="center"
            lineHeight={1}
          >
            <SoftTypography
              variant="h6"
              color={"text"}
              opacity={1}
              textTransform="capitalize"
              fontWeight="bold"
            >
              {t("balance")}:&nbsp;
            </SoftTypography>
            {allWalletStatus === STATUS.LOADING ? (
              <Loader size={"sm"} />
            ) : (
              <SoftTypography variant="h6" fontWeight="bold" color={"dark"}>
                $ {balance}
              </SoftTypography>
            )}
          </SoftBox>
        </SoftBox>
        {isMini ? null : (
          <SoftBox>
            <SoftBox
              display="flex"
              justifyContent="center"
              alignItems="center"
              color={light ? "white" : "inherit"}
              gap={1}
            >
              {(clientConfig.usePremiumModel && role !== 'admin' && subscription_status !== 'full') && <Link to={ROUTES.PROFILE} state={{ tab: 'plans' }}>
                <SoftTypography
                  variant="h6"
                  fontWeight="bold"
                  color={"dark"}
                  px="0.7vw"
                >
                  {t(
                    isUserFreemium
                      ? "Free Plan"
                      : isUserPremium
                        ? "Premium Plan"
                        : "Pro Plan"
                  )}
                </SoftTypography>
              </Link>}
              <FlagIcon handle={handleOpenLanguages} />
              <IconButton
                color="inherit"
                aria-controls="notification-menu"
                aria-haspopup="true"
                variant="contained"
                onClick={handleOpenMenu}
              >
                {someNotificationUnread ? (
                  <NotificationDot
                    overlap="circular"
                    anchorOrigin={{ vertical: "top", horizontal: "right" }}
                    variant="dot"
                    color="info"
                  >
                    <Icon className={light ? "text-white" : "text-dark"}>
                      notifications
                    </Icon>
                  </NotificationDot>
                ) : (
                  <Icon className={light ? "text-white" : "text-dark"}>
                    notifications
                  </Icon>
                )}
              </IconButton>
              <Link to={ROUTES.PROFILE} state={{ tab: 'profile' }}>
                {fullName && <SoftAvatar
                  alt="profile-image"
                  variant="rounded"
                  size="xs"
                  shadow="sm"
                  {...stringAvatar(fullName)}
                />}
              </Link>
              <NotificationsBell
                openMenu={openMenu}
                handleCloseMenu={handleCloseMenu}
                notifications={notifications}
              />
              <LanguageMenu
                anchorEl={anchorEl}
                open={openLanguages}
                handleClose={handleCloseLanguages}
              />
            </SoftBox>
          </SoftBox>
        )}
      </Toolbar>
    </AppBar>
  );
}

// Setting default values for the props of DashboardNavbar
DashboardNavbar.defaultProps = {
  absolute: false,
  light: false,
  isMini: false,
};

// Typechecking props for the DashboardNavbar
DashboardNavbar.propTypes = {
  absolute: PropTypes.bool,
  light: PropTypes.bool,
  isMini: PropTypes.bool,
};

export default DashboardNavbar;
