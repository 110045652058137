// @mui material components
import Grid from "@mui/material/Grid";
// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import { useDispatch, useSelector } from "react-redux";
import ExchangeInput from "../ExchangeInput";
import SoftTypography from "components/SoftTypography";
import ExchangeKeyButtons from "../ExchangeKeyButtons";
import { STATUS } from "constants/fetchStatus";
import { saveKeys } from "redux/actions/keys";
import { useTranslation } from "react-i18next";
import { useIsUserFreemium, useIsUserPremium } from "utils/useUserSubscriptionStatus";

// sweetalert2 components
import Swal from "sweetalert2";

import "../../index.css";

export default function KeyManager({ exchange }) {
  const { t } = useTranslation();
  const {
    label,
    referalLink,
    publicKey,
    privateKey,
    password,
    setPublicKey,
    setPrivateKey,
    setPassword,
    value,
    status,
    needsPassword,
  } = exchange;
  const dispatch = useDispatch();
  const handleChangePublicKey = (e) => setPublicKey(e.target.value.trim());
  const handleChangePrivateKey = (e) => setPrivateKey(e.target.value.trim());
  const handleChangePassword = (e) => setPassword(e.target.value.trim());
  const isUserFreemium = useIsUserFreemium();
  const isUserPremium = useIsUserPremium();

  const keysData = useSelector((state) => state.keys.data);
  const exchangesActiveCount = keysData.filter(
    (exchange) => exchange.isActive
  ).length;

  const handleSubmit = async () => {
    if (isUserFreemium && exchangesActiveCount >= 2)
      return Swal.fire({
        title: t("Oops..."),
        html: t("You need the premium or pro version to add more!"),
        timer: 4000,
        icon: "error",
      });
    if (isUserPremium && exchangesActiveCount >= 4)
      return Swal.fire({
        title: t("Oops..."),
        html: t("You need the pro version to add more!"),
        timer: 4000,
        icon: "error",
      });
    if (privateKey && publicKey) {
      dispatch(saveKeys(privateKey, publicKey, password, value));
    } else
      Swal.fire({
        title: t("Oops..."),
        html: t("You need to add keys"),
        timer: 4000,
        icon: "error",
      });
  };

  return (
    <SoftBox id="selectedExchanges">
      <Grid p={3} container spacing={3}>
        <Grid item xs={12} md={6} xl={6}>
          <ExchangeInput
            name="Public Key"
            value={publicKey}
            handle={handleChangePublicKey}
            type="text"
          />
        </Grid>
        <Grid item xs={12} md={6} xl={6}>
          <ExchangeInput
            name="Private Key"
            value={privateKey}
            handle={handleChangePrivateKey}
            type="password"
          />
        </Grid>
        {needsPassword && (
          <Grid item xs={12} md={6} xl={6}>
            <ExchangeInput
              name="Password"
              value={password}
              handle={handleChangePassword}
              type="password"
            />
          </Grid>
        )}
      </Grid>
      {status === STATUS.LOADING && (
        <center>
          <SoftTypography>{t("Validating")}</SoftTypography>
        </center>
      )}
      {status !== STATUS.LOADING && (
        <ExchangeKeyButtons
          referalLink={referalLink}
          label={label}
          handleSubmit={handleSubmit}
        />
      )}
    </SoftBox>
  );
}
