import io from "socket.io-client";
import { useEffect } from "react";
import { getNotifications } from 'redux/actions/notifications';
import { useDispatch, useSelector } from 'react-redux';
import { setToast } from 'redux/actions/toast';

export default function Sockets() {
    const dispatch = useDispatch()
    const { jwtToken } = useSelector(state => state.currentUser.data);
    useEffect(() => {
        const socket = io(process.env.REACT_APP_SOCKET_CONNECTION, {
            auth: { token: jwtToken },
            transports: ["polling"],
        });
        socket.on("notification", (data) => {
            dispatch(getNotifications());
            dispatch(setToast(true, data.title, data.body, data.status, data.icon));
            setTimeout(() => {
                dispatch(setToast(false, "", "", "", ""))
            }, 4500);
        });
        return () => {
            socket.disconnect();
        };
    }, []);
}
