// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import DataTable from "examples/Tables/DataTable";
import Side from "../TableItems/Side";
import Amount from "../TableItems/Amount";
import Price from "../TableItems/Price";
import Type from "../TableItems/Type";
import Date from "../TableItems/Date";
import SoftTypography from "components/SoftTypography";
import CryptoIconWithFetch from "componentsCustom/CryptoIcon/CryptoIconWithFetch";
// Translate
import { useTranslation } from 'react-i18next';

function tradesPairFormat(pair) {
    const convertedPair = pair.replace('/', '-');
    return convertedPair;
}

export default function FuturesTable({ trades }) {

    const { t } = useTranslation();

    const getTableColumns = () => {
        const initialArray = [
            {
                Header: "pair",
                accessor: "pair.pair",
                width: "15%",
                Cell: ({ row }) => <CryptoIconWithFetch pair={row.original.pair.pair} exchange='binance' />
            },
            {
                Header: "side",
                accessor: "trade.side",
                Cell: ({ row }) => <Side side={row.original.trade.side} />
            },
            {
                Header: "leverage",
                accessor: "trade.leverage",
                Cell: ({ row }) => <Type type={`X${row.original.trade.leverage}`} />
            },
            {
                Header: "amount",
                accessor: "trade.amount",
                Cell: ({ row }) => <Amount amount={row.original.trade.amount} pair={row.original.pair.pair} />
            },
            {
                Header: "entry price",
                accessor: "trade.entry_price",
                Cell: ({ row }) => <Price price={row.original.trade.entry_price} pair={row.original.pair.pair} />
            },
            {
                Header: "exit price",
                accessor: "trade.exit_price",
                Cell: ({ row }) => <Price price={row.original.trade.exit_price} pair={row.original.pair.pair} />
            },
            {
                Header: "pos. value",
                accessor: "trade.position_value",
                Cell: ({ row }) => <Price price={row.original.trade.position_value} pair={row.original.pair.pair} />
            },
            {
                Header: "status",
                accessor: "trade.status",
                Cell: ({ row }) => <Type type={row.original.trade.status} />
            },
            {
                Header: "opened at",
                accessor: "trade.opened_at",
                Cell: ({ row }) => <Date timestamp={row.original.trade.opened_at} />
            },
            {
                Header: "closed at",
                accessor: "trade.closed_at",
                Cell: ({ row }) => <Date timestamp={row.original.trade.closed_at} />
            }
        ]
        return initialArray
    }

    const getTableRows = () => {
        return trades.map(trade => {
            return {
                trade,
                pair: { pair: tradesPairFormat(trade.pair) },
            }
        })
    }

    const tableContent = {
        columns: getTableColumns(),
        rows: getTableRows()
    }

    return (
        <SoftBox mt={3}>
            {trades.length === 0 && <SoftTypography>{t('No trades yet!')}</SoftTypography>}
            {trades.length > 0 && <DataTable isSorted={true} canSearch={true} table={tableContent} defaultSortBy={"trade.timestamp"} />}
        </SoftBox>
    )
}