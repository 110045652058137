import { useSelector } from "react-redux"
import clientConfig from "./clientConfig";

const isUsePremiumModel = clientConfig.usePremiumModel;

export function useIsUserFreemium() {
    const { data: currentUser } = useSelector((state) => state.currentUser);

    if (!currentUser) {
        return false;
    }

    const { subscription_status, role, plan } = currentUser;
    const isFreemiumPlan = plan === "free";
    const isFreemiumSubscription =
        !subscription_status ||
        (subscription_status !== "active" && subscription_status !== "full");
    const isNotAdmin = role !== "admin";

    return isUsePremiumModel && isFreemiumPlan && isFreemiumSubscription && isNotAdmin;
}

export function useIsUserPremium() {
    const { data: currentUser } = useSelector((state) => state.currentUser);

    if (!currentUser) {
        return false;
    }

    const { subscription_status, role, plan } = currentUser;
    const isFreemiumPlan = plan === "premium";
    const isPremiumSubscription = subscription_status === "active";
    const isNotAdmin = role !== "admin";

    return isUsePremiumModel && isFreemiumPlan && isPremiumSubscription && isNotAdmin;
}

export function useIsUserPro() {
    const { data: currentUser } = useSelector((state) => state.currentUser);

    if (!currentUser) {
        return false;
    }

    const { subscription_status, role, plan } = currentUser;
    const isProPlan = plan === "pro";
    const isPremiumSubscription = subscription_status === "active";
    const isNotAdmin = role !== "admin";

    return isUsePremiumModel && isProPlan && isPremiumSubscription && isNotAdmin;
}
