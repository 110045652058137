import { useState, useRef } from "react";
import { useIsUserFreemium, useIsUserPremium } from "utils/useUserSubscriptionStatus";

// sweetalert2 components
import Swal from "sweetalert2";

// Redux
import { useDispatch, useSelector } from "react-redux";
import { toggleKeysActive } from "redux/actions/keys";

// @mui material components
import Grid from "@mui/material/Grid";
import { Card } from "@mui/material";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";

// Soft UI Dashboard React examples
import ExchangeIcon from "examples/Icons/Exchange";

// Soft UI Dashboard React componentsCustom
import ReduxStatusAnimations from "componentsCustom/ReduxStatusAnimations";

// Local components
import ExchangeSwitch from "../ExchangeSwitch";
import ExchangeButton from "../ExchangeButton";
import KeyManager from "../KeyManager";
import { STATUS } from "constants/fetchStatus";
import { useTranslation } from "react-i18next";
import "../../index.css";

export default function Exchange({ exchange }) {
  const { t } = useTranslation();
  const exchangeRef = useRef(null);
  const { label, img, value } = exchange;
  const keysData = useSelector((state) => state.keys.data);
  const exchangeKeysData = keysData.find((data) => data.exchange === value);
  const exchangesActiveCount = keysData.filter(
    (exchange) => exchange.isActive
  ).length;
  const active = exchangeKeysData?.isActive || false;
  const status = exchangeKeysData?.status || STATUS.PENDING;

  const dispatch = useDispatch();

  const [publicKey, setPublicKey] = useState(exchangeKeysData?.publicKey || "");
  const [privateKey, setPrivateKey] = useState(
    exchangeKeysData?.privateKey || ""
  );
  const [password, setPassword] = useState(exchangeKeysData?.password || "");
  const [opened, setOpened] = useState(false);
  const isUserFreemium = useIsUserFreemium();
  const isUserPremium = useIsUserPremium();

  const handleOpen = () => {
    setOpened((prevOpened) => !prevOpened);
  };

  const submitActive = () => {
    if (isUserFreemium && !active && exchangesActiveCount >= 2)
      return Swal.fire({
        title: t("Oops..."),
        html: t("You need the premium or pro version to add more!"),
        timer: 4000,
        icon: "error",
      });
    if (isUserPremium && !active && exchangesActiveCount >= 4)
      return Swal.fire({
        title: t("Oops..."),
        html: t("You need the pro version to add more!"),
        timer: 4000,
        icon: "error",
      });
    if (publicKey && privateKey) {
      dispatch(toggleKeysActive(exchange.value, !active));
    } else
      Swal.fire({
        title: t("Oops..."),
        html: t("You need to add keys"),
        timer: 4000,
        icon: "error",
      });
  };

  const handleClick = () => {
    exchangeRef.current.id = "selectedExchanges";
  };

  return (
    <SoftBox mt={0} mb={4}>
      <Card ref={exchangeRef} onClick={handleClick} className="exchange-card">
        <Grid
          container
          justifyContent={{ xs: "space-between", md: "space-around" }}
          alignItems="center"
        >
          <Grid item xs={4} md={4}>
            <ExchangeIcon icon={img} label={label} />
          </Grid>
          <Grid item md={4}>
            <ExchangeSwitch
              active={active}
              activeLoading={status === STATUS.LOADING}
              submitActive={submitActive}
            />
          </Grid>
          <Grid item>
            <ExchangeButton handleOpen={handleOpen} />
          </Grid>
        </Grid>
        {opened && (
          <KeyManager
            exchange={{
              ...exchange,
              publicKey,
              privateKey,
              password,
              setPublicKey,
              setPrivateKey,
              setPassword,
              status,
            }}
          />
        )}
        <ReduxStatusAnimations status={status} />
      </Card>
    </SoftBox>
  );
}
