import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

// react-redux
import { useSelector, useDispatch } from "react-redux";
import { logoutSesion } from "redux/actions/currentUser";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftAvatar from "components/SoftAvatar";

// Soft UI Dashboard React base styles
import breakpoints from "assets/theme/base/breakpoints";

// Images
import curved0 from "assets/images/curved-images/curved0.jpg";

// Utils
import { stringAvatar } from "utils/stringAvatar";
import { getDataTabs } from "../../schemas/tabs";
import clientConfig from "utils/clientConfig";

// Translate
import { useTranslation } from "react-i18next";

// Constants
import { ROUTES } from "constants";
import { useIsUserFreemium, useIsUserPremium } from "utils/useUserSubscriptionStatus";

function Header({ tabValue, setTabValue }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { fullName, email, subscription_status, role } = useSelector(
    (state) => state.currentUser.data
  );

  const isUserFreemium = useIsUserFreemium();
  const isUserPremium = useIsUserPremium();

  const [tabsOrientation, setTabsOrientation] = useState("horizontal");

  useEffect(() => {
    // A function that sets the orientation state of the tabs.
    function handleTabsOrientation() {
      return window.innerWidth < breakpoints.values.sm
        ? setTabsOrientation("vertical")
        : setTabsOrientation("horizontal");
    }

    /** 
     The event listener that's calling the handleTabsOrientation function when resizing the window.
    */
    window.addEventListener("resize", handleTabsOrientation);

    // Call the handleTabsOrientation function to set the state with the initial value.
    handleTabsOrientation();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleTabsOrientation);
  }, [tabsOrientation]);

  const handleSetTabValue = (event, newValue) => setTabValue(newValue);

  const handleClick = () => {
    dispatch(logoutSesion());
    navigate(ROUTES.LOGIN);
  };

  return (
    <SoftBox id="profileSection" position="relative">
      <SoftBox
        display="flex"
        alignItems="center"
        position="relative"
        minHeight="18.75rem"
        borderRadius="xl"
        sx={{
          backgroundImage: ({
            functions: { rgba, linearGradient },
            palette: { gradients },
          }) =>
            `${linearGradient(
              rgba(gradients.info.main, 0.6),
              rgba(gradients.info.state, 0.6)
            )}, url(${curved0})`,
          backgroundSize: "cover",
          backgroundPosition: "50%",
          overflow: "hidden",
        }}
      />
      <Card
        sx={{
          backdropFilter: `saturate(200%) blur(30px)`,
          backgroundColor: ({ functions: { rgba }, palette: { white } }) =>
            rgba(white.main, 0.8),
          boxShadow: ({ boxShadows: { navbarBoxShadow } }) => navbarBoxShadow,
          position: "relative",
          mt: -8,
          mx: 3,
          py: 2,
          px: 2,
        }}
      >
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={12} md={4}>
            <SoftBox
              display="flex"
              justifyContent={{
                xs: "center",
                md: "flex-start",
                lg: "flex-start",
              }}
              alignItems="center"
              gap={1}
            >
              <SoftAvatar
                alt="profile-image"
                variant="rounded"
                size="xl"
                shadow="sm"
                {...stringAvatar(fullName)}
              />
              <SoftBox
                display="flex"
                flexDirection="column"
                height="100%"
                mt={0.5}
                lineHeight={1}
              >
                <SoftTypography variant="h5" fontWeight="medium">
                  {fullName}
                </SoftTypography>
                <SoftTypography
                  variant="button"
                  color="text"
                  fontWeight="medium"
                >
                  {email}
                </SoftTypography>
                {clientConfig.usePremiumModel && role !== "admin" && subscription_status !== "full" && (
                  <SoftTypography
                    variant="button"
                    color="text"
                    fontWeight="medium"
                  >
                    {t(
                      isUserFreemium
                        ? "Free Plan"
                        : isUserPremium
                          ? "Premium Plan"
                          : "Pro Plan"
                    )}
                  </SoftTypography>
                )}
              </SoftBox>
            </SoftBox>
          </Grid>
          <Grid item xs={12} md={8}>
            <AppBar position="static">
              <Tabs
                orientation={tabsOrientation}
                value={tabValue}
                onChange={handleSetTabValue}
                sx={{ background: "transparent" }}
              >
                {getDataTabs(role, subscription_status).map((tab) => {
                  const { key, name, icon, value } = tab;
                  return (
                    tab && (
                      <Tab
                        id={name === "sign out" ? "signout" : name}
                        key={key}
                        name={name}
                        label={
                          <SoftTypography
                            variant="button"
                            color="text"
                            fontWeight="medium"
                            textTransform="capitalize"
                          >
                            {t(name)}
                          </SoftTypography>
                        }
                        icon={icon}
                        onClick={name === "sign out" ? handleClick : null}
                        value={value}
                      />
                    )
                  );
                })}
              </Tabs>
            </AppBar>
          </Grid>
        </Grid>
      </Card>
    </SoftBox>
  );
}

export default Header;
