// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import DataTable from "examples/Tables/DataTable";
import Side from "../TableItems/Side";
import Amount from "../TableItems/Amount";
import Cost from "../TableItems/Cost";
import Price from "../TableItems/Price";
import Exchange from "../TableItems/Exchange";
import Type from "../TableItems/Type";
import Date from "../TableItems/Date";
import SoftTypography from "components/SoftTypography";
import CancelButton from "../TableItems/CancelButton";
import CryptoIconWithFetch from "componentsCustom/CryptoIcon/CryptoIconWithFetch";
// Translate
import { useTranslation } from 'react-i18next';
import { timestampToMs } from "utils/sortOrdersByTimestamp";

function tradesPairFormat(pair) {
    const convertedPair = pair.replace('/', '-');
    return convertedPair;
}

export default function TradesTable({ trades, selectedOrderType }) {

    const { t } = useTranslation();

    const getTableColumns = () => {
        const initialArray = [
            {
                Header: "pair",
                accessor: "pair.pair",
                width: "15%",
                Cell: ({ row }) => <CryptoIconWithFetch pair={row.original.pair.pair} exchange={row.original.trade.exchange} />
            },
            {
                Header: "side",
                accessor: "trade.side",
                width: "6%",
                Cell: ({ row }) => <Side side={row.original.trade.side} />
            },
            {
                Header: "amount",
                accessor: "trade.amount",
                width: "10%",
                Cell: ({ row }) => <Amount amount={row.original.trade.amount} pair={row.original.pair.pair} />
            },
            {
                Header: "price",
                accessor: "trade.price",
                width: "12%",
                Cell: ({ row }) => <Price price={row.original.trade.price} pair={row.original.pair.pair} />
            },
            {
                Header: "cost",
                accessor: "trade.cost",
                width: "12%",
                Cell: ({ row }) => <Cost cost={row.original.trade.cost} pair={row.original.pair.pair} />
            },
            {
                Header: "exchange",
                accessor: "trade.exchange",
                width: "8%",
                Cell: ({ row }) => <Exchange exchange={row.original.trade.exchange} />
            },
            {
                Header: "type",
                accessor: "trade.type",
                width: "8%",
                Cell: ({ row }) => <Type type={row.original.trade.type} />
            },
            {
                Header: "submitted_by",
                accessor: "trade.submittedBy",
                width: "10%",
                Cell: ({ row }) => <Type type={row.original.trade.submittedBy} />
            },
            {
                Header: "date",
                accessor: "trade.timestamp",
                width: "12%",
                Cell: ({ row }) => <Date timestamp={row.original.trade.timestamp} />
            }
        ]
        if (selectedOrderType === "open") initialArray.push({ Header: "cancel", accessor: "cancel", width: "10%" })
        return initialArray
    }

    const getTableRows = () => {
        return trades.map(trade => {
            return {
                trade: {
                    ...trade,
                    cost: trade.amount * trade.price,
                    timestamp: timestampToMs(trade.timestamp)
                },
                pair: { pair: tradesPairFormat(trade.pair) },
                cancel: <CancelButton trade={trade} />
            }
        })
    }

    const tableContent = {
        columns: getTableColumns(),
        rows: getTableRows()
    }

    return (
        <SoftBox mt={3}>
            {trades.length === 0 && <SoftTypography>{t('No trades yet!')}</SoftTypography>}
            {trades.length > 0 && <DataTable isSorted={true} canSearch={true} table={tableContent} defaultSortBy={"trade.timestamp"} />}
        </SoftBox>
    )
}