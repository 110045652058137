import { useEffect } from 'react'
// @mui material components
import Card from "@mui/material/Card";
import Stack from "@mui/material/Stack";
// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";
// Soft UI Dashboard PRO React example components
import DataTable from "examples/Tables/DataTable";
// Local components
import Exchange from "componentsCustom/TableItems/Exchange";
import Pair from "componentsCustom/TableItems/Pair";
import Amount from "componentsCustom/TableItems/Amount";
import Actions from './TableItems/Actions';
import RequiredBalance from "./TableItems/RequiredBalance";
// Translate
import { useTranslation } from 'react-i18next';
import ProfitPerCent from "componentsCustom/TableItems/ProfitPerCent";
import { useIsUserFreemium, useIsUserPremium } from "utils/useUserSubscriptionStatus";

// sweetalert2 components
import Swal from "sweetalert2";
import useTour from 'hooks/tours/useTour';

export default function StrategiesTable({ strategies, setShowComponent }) {

    const { t } = useTranslation();
    const { TourComponent, setTourSection } = useTour();
    const isUserFreemium = useIsUserFreemium()
    const isUserPremium = useIsUserPremium()
    const rows = strategies.map(strategy => {
        const { id, exchange1, exchange2, pair, amount, profit } = strategy;
        return {
            key: id,
            id,
            inputs: false,
            exchange1: <Exchange exchange={exchange1} />,
            exchange2: <Exchange exchange={exchange2} />,
            pair: <Pair pair={pair} />,
            amount: <Amount amount={amount} symbol={pair.split("-")[0]} />,
            profit: <ProfitPerCent profit={profit} />,
            actions: <Actions idStategy={id} setShowComponent={setShowComponent} />,
            requiredBalance: <RequiredBalance strategy={strategy} />
        };
    });
    const columns = [
        { Header: "exchange 1", accessor: "exchange1", width: "18%" },
        { Header: "exchange 2", accessor: "exchange2", width: "18%" },
        { Header: "pair", accessor: "pair", width: "18%" },
        { Header: "min profit", accessor: "profit", width: "18%" },
        { Header: "amount", accessor: "amount", width: "18%" },
        { Header: "Required Balance", accessor: "requiredBalance" },
        { Header: "actions", accessor: "actions", width: "10%" },
    ];
    const table = { columns, rows }
    const handleAdd = (event) => {
        if (isUserFreemium && strategies.length > 1) return Swal.fire({
            title: t("Oops..."),
            html: t("You need the premium or pro version to add more!"),
            timer: 4000,
            icon: "error",
        });
        if (isUserPremium && strategies.length > 4) return Swal.fire({
            title: t("Oops..."),
            html: t("You need the pro version to add more!"),
            timer: 4000,
            icon: "error",
        });
        if (strategies.length > 8) return Swal.fire({
            title: t("Oops..."),
            html: t("You have reached your limit!"),
            timer: 4000,
            icon: "error",
        });
        event.preventDefault();
        setShowComponent(prev => ({ ...prev, newStrategy: true, table: false }));
    };

    useEffect(() => {
        strategies.length === 0 ? setTourSection("addStrategy") : setTourSection("strategies");
    }, [strategies]);

    return (
        <SoftBox>
            {TourComponent}
            <Card id="strategiesHeader">
                <SoftBox display="flex" justifyContent="space-between" alignItems="flex-start" p={3}>
                    <SoftBox lineHeight={1}>
                        <SoftTypography variant="h5" fontWeight="medium">
                            {strategies.length === 0 ? t("No strategies yet!") : t("All Strategies")}
                        </SoftTypography>
                    </SoftBox>
                    <Stack spacing={1} direction="row">
                        <SoftButton
                            id="newStrategyButton"
                            variant="gradient"
                            color="info"
                            size="small"
                            disabled={strategies.length > 9 ? true : false}
                            onClick={handleAdd}

                        >
                            + {t('new strategy')}
                        </SoftButton>
                    </Stack>
                </SoftBox>
                {strategies.length > 0 && <DataTable
                    entriesPerPage={false}
                    showTotalEntries={false}
                    isSorted={false}
                    canSearch={false}
                    table={table}
                />}
            </Card>
        </SoftBox>
    );
}
