import Menu from "@mui/material/Menu";
import { useDispatch } from "react-redux";
// Soft UI Dashboard PRO React example components
import NotificationItem from "examples/Items/NotificationItem";
// react-router components
import { Link, useNavigate } from "react-router-dom";
// @mui core components
import Divider from "@mui/material/Divider";
import { ROUTES } from "constants";
// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";
import { setNotificationsAsRead } from "redux/actions/notifications";

// Translate
import { useTranslation } from 'react-i18next';

export default function NotificationsBell({ openMenu, handleCloseMenu, notifications }) {
    const navigate = useNavigate();
    const { t } = useTranslation();

    const unreadCount = notifications.reduce((count, notification) => {
        return count + (notification.read ? 0 : 1);
    }, 0);

    const dispatch = useDispatch()
    const handleMarkReadButton = () => {
        dispatch(setNotificationsAsRead())
    }

    const handleViewAll = event => {
        event.preventDefault();
        dispatch(setNotificationsAsRead())
        navigate(ROUTES.NOTIFICATIONS);
    };

    return (
        <Menu
            anchorEl={openMenu}
            anchorReference={null}
            anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
            }}
            open={Boolean(openMenu)}
            onClose={handleCloseMenu}
            sx={{ mt: 2 }}
        >
            <SoftBox display="flex" justifyContent="space-between" px={1}>
                <SoftBox m={1}>
                    <SoftButton onClick={handleViewAll} variant="gradient" color="info" size="small">
                        {t("View all")}
                    </SoftButton>
                </SoftBox>
                <SoftBox m={1}>
                    <SoftButton onClick={handleMarkReadButton} variant="gradient" color="info" size="small">
                        {t("Mark as read")}
                    </SoftButton>
                </SoftBox>
            </SoftBox>
            <Divider />
            <SoftBox mx={2}>
                <SoftTypography
                    variant="p"
                    color="text"
                    opacity={1}
                    textTransform="capitalize"
                    fontWeight="bold"
                >
                    {unreadCount} {t("unread notifications")}
                </SoftTypography>
            </SoftBox>
            {notifications.slice(0, 5).map(notification => (<Link key={notification.id} to={ROUTES.NOTIFICATIONS}>
                <SoftBox mt={1}>
                    <NotificationItem
                        color={notification.status}
                        title={[t(notification.title)]}
                        date={notification.body}
                        read={notification.read}
                        icon={notification.icon}
                        onClick={handleCloseMenu}
                    />
                </SoftBox>
            </Link>
            ))}
        </Menu>
    )
}