import { useEffect } from 'react'
// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import TradingChart from "./components/TradingChart";
import TradeModal from "./components/TradeModal";
import usePairData from "utils/usePairData";
import './index.scss';
import useTour from 'hooks/tours/useTour';
import { exchangesData } from 'data/exchanges';

export default function TradingPage({ setChartOpened, selectedExchange, selectedPair }) {
    const pairData = usePairData(selectedPair, selectedExchange);
    const { TourComponent, setTourSection } = useTour();
    const selectedExchangeData = exchangesData.find(exchange => exchange.value === selectedExchange);

    useEffect(() => {
        setTourSection("tradeModal")
    }, []);

    return (
        <SoftBox id="tradeModal" sx={{ position: 'relative' }}>
            {TourComponent}
            <SoftBox
                sx={{
                    position: 'absolute',
                    left: '-40%',
                    top: '-10%'
                }}
            >
                <TradeModal setChartOpened={setChartOpened} pairData={pairData} />
            </SoftBox>
            <SoftBox sx={{ position: 'relative' }}>
                {!selectedExchangeData.tradingView && <SoftBox
                    width="100%"
                    height="100%"
                    sx={{
                        position: 'absolute',
                        zIndex: 10,
                        borderRadius: "12px",
                        backdropFilter: "blur(4px)",
                        backgroundColor: 'rgba(0,0,30,0.4)',
                    }}
                />}
                <TradingChart selectedPair={selectedExchangeData.tradingView ? selectedPair : 'BTC-USDT'} selectedExchange={selectedExchangeData.tradingView ? selectedExchange : 'binance'} />
            </SoftBox>
        </SoftBox>
    );
}

