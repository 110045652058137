import React, { useState, useEffect } from 'react'

// Redux
import { useSelector } from "react-redux";
import { addBot } from 'api/bots';
import { deleteBotCopyTrade } from 'api/bots';

// @mui material components
import Divider from "@mui/material/Divider";
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";
import QueryStatsSharpIcon from '@mui/icons-material/QueryStatsSharp';

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftSelect from 'components/SoftSelect';
import ExchangeIcon from 'examples/Icons/Exchange';

// Soft UI Dashboard PRO Colors
import colors from 'assets/theme/base/colors';

//Data
import { exchangesData } from "data/exchanges";

// Utils
import { parseActiveExchangesFromKeys } from "utils/parseActiveExchangesFromKeys";

// Translate
import { useTranslation } from "react-i18next";

const Bot = () => {
    const { t } = useTranslation();
    const botsData = useSelector((state) => state.bots.data);
    const bot = botsData.find((bot) => bot.bot_id === 1);
    const activeExchanges = parseActiveExchangesFromKeys();
    const [state, setState] = useState(!!bot);
    const [loading, setLoading] = useState(false);
    const [exchange, setExchange] = useState(bot?.exchange || null);
    const [exchangeData, setExchangeData] = useState({})

    const handleSubmit = async (event) => {
        event.preventDefault();
        const tempState = !state;
        setLoading(true);
        if (tempState) {
            const { success } = await addBot({ bot_id: 1, exchange, amount: 0 })
            setState(success)
        } else {
            const suscriptionId = bot.suscription_id || null;
            if (suscriptionId) {
                const { success } = await deleteBotCopyTrade(suscriptionId);
                if (success) {
                    setState(false);
                }
            }
        }
        setLoading(false);
    }

    useEffect(() => {
        if (exchange) {
            const data = exchangesData.find(({ value }) => value === exchange)
            data && setExchangeData(data)
        }
    }, [exchange])

    return (
        <Card sx={{ height: "100%" }}>
            <SoftBox
                component="form"
                p={3}
                height="100%"
                bgColor={state ? "info" : "white"}
                variant="gradient"
                display="flex"
                flexDirection="column"
                justifyContent="space-between"
                onSubmit={handleSubmit}
            >
                <SoftBox
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    mb={2}
                    lineHeight={1}
                >
                    <SoftTypography variant="body2" color={state ? "white" : "text"} fontWeight="bold">
                        {t(state ? "On" : "Off")}
                    </SoftTypography>
                    <SoftBox>
                        <Switch disabled={loading} checked={state} type="submit" />
                    </SoftBox>
                </SoftBox>
                {
                    state ? (
                        <QueryStatsSharpIcon
                            fontSize="large"
                            sx={{
                                bgcolor: colors.white.main,
                                p: 1,
                                height: "50px",
                                width: "50px",
                                borderRadius: 50
                            }}
                        />
                    ) : (
                        <QueryStatsSharpIcon
                            fontSize="large"
                            color="white"
                            sx={{
                                bgcolor: colors.dark.main,
                                p: 1,
                                height: "50px",
                                width: "50px",
                                borderRadius: 50
                            }}
                        />
                    )
                }
                <SoftBox mt={1} lineHeight={1}>
                    <SoftTypography
                        variant="body2"
                        color={state ? "white" : "text"}
                        textTransform="capitalize"
                        fontWeight="medium"
                    >
                        {t("Bot")}
                    </SoftTypography>
                    <SoftTypography variant="caption" color={state ? "white" : "text"}>
                        {t("To use the Bot, make sure to")}{" "}
                        <strong>{t("select an active exchange")}</strong>.{" "}
                        {t("The bot will operate exclusively on the selected exchange, applying trading strategies and executing trades on the configured pairs. Please note that the minimum amount required to activate the bot is")} {" "}
                        <strong>{t("500 USD")}</strong>.{" "}
                    </SoftTypography>
                    <SoftBox
                        gap={3}
                        mt={4}
                        display="flex"
                        flexDirection="column"
                    >
                        <SoftTypography variant="caption" color={state ? "white" : "text"}>
                            📈 <strong>{t("Gross Profit")} (%): 22.81</strong>
                        </SoftTypography>
                        <SoftTypography variant="caption" color={state ? "white" : "text"}>
                            📊 <strong>{t("Sharpe Ratio")}: 3.16</strong>
                        </SoftTypography>
                        <SoftTypography variant="caption" color={state ? "white" : "text"}>
                            📉 <strong>{t("Sortino Ratio")}: 1.00</strong>
                        </SoftTypography>
                        <SoftTypography variant="caption" color={state ? "white" : "text"}>
                            📉 <strong>{t("Maximum Drawdown")} (%): -8.86</strong>
                        </SoftTypography>
                        <SoftTypography variant="caption" color={state ? "white" : "text"}>
                            🚀 <strong>{t("Maximum Run-Up")} (%): 30.55</strong>
                        </SoftTypography>
                    </SoftBox>
                </SoftBox>
                <Divider />
                <SoftBox mb={3} sx={{ color: "red" }}>
                    <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                        <SoftTypography
                            component="label"
                            variant="caption"
                            fontWeight="bold"
                            textTransform="capitalize"
                            color={state ? "white" : "text"}
                        >
                            {t("Exchange")}
                        </SoftTypography>
                    </SoftBox>
                    {!state ?
                        <SoftSelect
                            size="small"
                            options={activeExchanges.map((exch) => ({ value: exch, label: exch }))}
                            autoFocus
                            defaultValue={exchange}
                            onChange={(e) => setExchange(e.value)}
                            placeholder={t("Select...")}
                            menuPosition="fixed"
                            required
                            isDisabled={state}
                        /> :
                        <ExchangeIcon icon={exchangeData?.img} label={exchangeData?.label} textColor={state ? "white" : "text"} />
                    }

                </SoftBox>
            </SoftBox>
        </Card>
    )
};

export default Bot;