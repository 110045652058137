/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.2
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { useSelector } from "react-redux";

// sweetalert2 components
import Swal from "sweetalert2";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";

// Soft UI Dashboard PRO React example components
import DefaultPricingCard from "examples/Cards/PricingCards/DefaultPricingCard";

// Checkout
import { fetchCheckout } from "api/checkout";
import { fetchUnsubscription } from "api/checkout";

import { useIsUserPremium, useIsUserPro } from "utils/useUserSubscriptionStatus";

// Translate
import { useTranslation } from "react-i18next";
import { getMessages } from "redux/actions/messages";
import { ROUTES } from "constants";

function PricingCards({ prices, contactUsHandler, paymentFrequency }) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { subscription_status } = useSelector(
    (state) => state.currentUser.data
  );
  const { free, premium, pro, corporate } = prices;
  const [isSubscribed, setisSubscribed] = useState(false);
  const isUserPremium = useIsUserPremium();
  const isUserPro = useIsUserPro();

  const handleCheckout = async (priceId) => {
    await fetchCheckout(priceId);
  };

  const handleUnsubscription = async () => {
    const newSwal = Swal.mixin({
      customClass: {
        confirmButton: "button button-success",
        cancelButton: "button button-error",
      },
      buttonsStyling: false,
    });

    newSwal
      .fire({
        title: t("Are you sure?"),
        text: t("Do you really want to unsubscribe?"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: t("Yes, unsubscribe!"),
        cancelButtonText: t("No, keep my subscription"),
      })
      .then((result) => {
        if (result.isConfirmed) {
          fetchUnsubscription()
            .then(() => {
              Swal.fire(
                t("Unsubscribed!"),
                t(
                  "Your subscription has been successfully canceled. We're sorry to see you go!"
                ),
                "success"
              ).then(() => navigate(ROUTES.ROOT));
            })
            .catch(() => {
              Swal.fire(
                t("Oops..."),
                t(
                  "There was an error canceling your subscription. Please try again or contact support if the issue persists."
                ),
                "error"
              );
            });
        }
      });
  };

  useEffect(() => {
    subscription_status === "active"
      ? setisSubscribed(true)
      : setisSubscribed(false);
  }, [subscription_status]);

  return (
    <SoftBox
      position="relative"
      zIndex={10}
      mt={{ xs: "-10rem", lg: "-8rem" }}
      mb={3}
      px={{ xs: 1, sm: 0 }}
    >
      <Grid container spacing={3} justifyContent="center">
        {/* <Grid item xs={12} lg={4}>
          <DefaultPricingCard
            badge={{ color: "secondary", label: free.name }}
            price={{ currency: "", value: free.value }}
            specifications={free.specifications.map(({ label, includes }) => ({
              label,
              icon: includes ? "done" : "close",
              color: includes ? "success" : "secondary",
              size: "1.5rem",
            }))}
            action={{
              handle: () => handleCheckout(free.id),
              color: "dark",
              label: "start now",
              disabled: isSubscribed,
            }}
          />
        </Grid> */}
        <Grid item xs={12} lg={4}>
          <DefaultPricingCard
            badge={{ color: "secondary", label: premium.name }}
            price={{ currency: "", value: premium.value }}
            specifications={premium.specifications.map(
              ({ label, includes }) => ({
                label,
                icon: includes ? "done" : "close",
                color: includes ? "success" : "secondary",
                size: "1.5rem",
              })
            )}
            action={{
              handle: () =>
                isSubscribed && isUserPremium
                  ? handleUnsubscription()
                  : handleCheckout(premium.id),
              color: "info",
              label: isSubscribed && isUserPremium ? "cancel suscription" : "start now",
              disabled: false,
            }}
          />
        </Grid>
        <Grid item xs={12} lg={4}>
          <DefaultPricingCard
            badge={{ color: "secondary", label: pro.name }}
            price={{ currency: "", value: pro.value, calcPrice: paymentFrequency === 'annual'?'$420':'' }}
            specifications={pro.specifications.map(
              ({ label, includes }) => ({
                label,
                icon: includes ? "done" : "close",
                color: includes ? "success" : "secondary",
                size: "1.5rem",
              })
            )}
            action={{
              handle: () =>
                isSubscribed && isUserPro
                  ? handleUnsubscription()
                  : handleCheckout(pro.id),
              color: "info",
              label: isSubscribed && isUserPro ? "cancel suscription" : "start now",
              disabled: false,
            }}
          />
        </Grid>
        <Grid item xs={12} lg={4}>
          <DefaultPricingCard
            badge={{ color: "secondary", label: corporate.name }}
            price={{ currency: "", value: corporate.value }}
            specifications={corporate.specifications.map(
              ({ label, includes }) => ({
                label,
                color: includes ? "success" : "secondary",
                size: "1.5rem",
              })
            )}
            action={{
              handle: () => contactUsHandler(),
              color: "dark",
              label: "Contact us",
              disabled: false,
            }}
          />
        </Grid>
      </Grid>
    </SoftBox>
  );
}

// Typechecking props for the PricingCards
PricingCards.propTypes = {
  prices: PropTypes.object.isRequired,
  contactUsHandler: PropTypes.func.isRequired,
};

export default PricingCards;
