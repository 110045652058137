import { useState } from 'react'
import { useParams } from 'react-router-dom';
import clientConfig from 'utils/clientConfig';

// Favicons
import app_favicon from "assets/images/logos/ctrading_favicon.png";
import zion_favicon from "assets/images/logos/zion_favicon.avif";
import academyx10_icon from "assets/images/logos/academyx10_icon.avif";

// Logos
import zion_logo_transparent from "assets/images/logos/zion_logo(transparent).avif";
import zion_logo from "assets/images/logos/zion_logo.avif";
import app_logo from "assets/images/logos/ctrading_logo(transparent).png";
import generic_logo from "assets/images/logos/generic_logo.avif";

// Illustrations
import danger_chat from "assets/images/illustrations/danger-chat-ill.png";
import lock from "assets/images/illustrations/lock.png";
import chat from "assets/images/illustrations/chat.png";
import rocket_white from "assets/images/illustrations/rocket-white.png";
import canvio_logo from "assets/images/illustrations/canvio-logo.png";
import zion_ilustration from "assets/images/illustrations/zion_ilustration.avif";
import app_ctrading from "assets/images/illustrations/app_ctrading.avif";

const clientFavicons = {
    app: app_favicon,
    zion: zion_favicon,
    academyx10: academyx10_icon,
}

const clientLogos = {
    zion: zion_logo_transparent,
    app: app_logo,
}

const defaultIllustrations = {
    "2fa": danger_chat,
    "forgot-password": lock,
    login: chat,
    register: rocket_white,
    "reset-password": lock,
}

const clientsIllustrations = {
    app: app_ctrading,
    "canviodayone-notrading": canvio_logo,
    zion: zion_ilustration,
    academyx10: academyx10_icon,
}

const errorLogos = {
    app: app_logo,
    zion: zion_logo,
    "canviodayone-notrading": canvio_logo,
    academyx10: academyx10_icon,
}

const styles = {
    app: {
        objectFit: "fill",
        position: "absolute",
    },
    zion: {
        height: "100%",
        objectFit: "fill",
        position: "absolute",
        top: 0,
        left: 0

    }
}

const bgColor = {
    app: "transparent",
    zion: "transparent",
}

const information = {
    app: {
        // login: {
        //     title: "ULTIMATE TRADING",
        //     description: "Where the new generation of trading begins."
        // }
    }
}

const useAssets = () => {
    const { authType } = useParams();
    const { name } = clientConfig;
    const [favicon] = useState(clientFavicons[name] || generic_logo);
    const [logo] = useState(clientLogos[name] || generic_logo);
    const [illustration] = useState({
        image: clientsIllustrations[name]?.[authType] || clientsIllustrations[name] || defaultIllustrations[authType] || chat,
        styles: styles[name] || { maxWidth: "31.25rem" }
    });
    const [errorLogo] = useState(errorLogos[name] || generic_logo);
    const [illustrationInfo] = useState(information[name]?.[authType] || information[name] || { title: "", description: "" });
    const [illustrationBGClolor] = useState(bgColor[name] || null)

    return { logo, favicon, illustration, errorLogo, illustrationInfo, illustrationBGClolor }
}

export default useAssets