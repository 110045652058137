// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

function ExchangeIcon({ icon, label, textColor = "dark" }) {
  return (
    <SoftBox display="flex" alignItems="center" gap={1}>
      <SoftBox height={{ xs: 25, md: 30 }}>
        <img width={"auto"} height="100%" src={icon} alt={label} />
      </SoftBox>
      <SoftTypography
        fontWeight="bold"
        fontSize={{ xs: 15, md: 20 }}
        variant="section"
        color={textColor}
      >
        {label}
      </SoftTypography>
    </SoftBox>
  );
}

// Typechecking props for the ExchangeIcon
ExchangeIcon.propTypes = {
  icon: PropTypes.node.isRequired,
  label: PropTypes.string.isRequired,
};

export default ExchangeIcon;
