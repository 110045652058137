/***** We receive an action and set de data to the store *****/

import { LOADING_ORDERS, ADD_ORDERS, SET_ORDERS, UPDATE_ORDERS_BY_EXCHANGE } from "constants/actionTypes"
import sortOrdersByTimestamp from "utils/sortOrdersByTimestamp"

const initialOrdersData = { trades: [] }

export default (orders = { data: initialOrdersData, loading: false }, action) => {
    switch (action.type) {
        case SET_ORDERS:
            return { ...orders, data: { trades: action.payload } }
        case UPDATE_ORDERS_BY_EXCHANGE: {
            const { fetchedOrders, exchange } = action.payload
            const previousOrders = [...orders.data.trades]
            const filteredOrders = previousOrders.filter(order => order.exchange !== exchange)
            const allOrders = [...filteredOrders, ...fetchedOrders]
            return { ...orders, data: { trades: sortOrdersByTimestamp(allOrders) } }
        }
        case ADD_ORDERS:
            const updatedOrders = orders?.data.filter(
                oldOrder => !action.payload.some(newOrder => newOrder.id === oldOrder.id)
            );
            const sortedOrders = sortOrdersByTimestamp([...updatedOrders, ...action.payload])
            return { ...orders, data: sortedOrders };
        case LOADING_ORDERS:
            return { ...orders, loading: action.payload }
        default:
            return orders
    }
}