/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.2
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useSelector } from "react-redux";
// @mui material components
import Grid from "@mui/material/Grid";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import TimelineList from "examples/Timeline/TimelineList";
import TimelineItem from "examples/Timeline/TimelineItem";
import SoftTypography from "components/SoftTypography";

// Data
import { timestampToDateTime } from "utils/timestampToDateTime";

// Translate
import { useTranslation } from "react-i18next";

function Notifications() {
  const { t } = useTranslation();
  const notifications = useSelector((state) => state.notifications);
  const renderTimelineItems = notifications.map((notification, i) => {
    const { id, title, body, status, timestamp, icon, tags } = notification;
    return (
      <TimelineItem
        key={id}
        color={status}
        icon={icon}
        title={t(title)}
        dateTime={timestampToDateTime(timestamp)}
        description={body}
        badges={tags}
        lastItem={i === notifications.length - 1}
      />
    );
  });

  return (
    <DashboardLayout>
      <SoftBox my={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={3}></Grid>
          <Grid item xs={12} lg={6}>
            {notifications.length > 0 && (
              <TimelineList title={t("Notifications")}>
                {renderTimelineItems}
              </TimelineList>
            )}
            {notifications.length === 0 && (
              <SoftTypography variant="text" fontWeight="medium">
                {t("No notifications!")}
              </SoftTypography>
            )}
          </Grid>
          <Grid item xs={12} lg={3}></Grid>
        </Grid>
      </SoftBox>
    </DashboardLayout>
  );
}

export default Notifications;
