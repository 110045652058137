// @mui material components
import Icon from "@mui/material/Icon";
// Soft UI Dashboard PRO React components
import SoftTypography from "components/SoftTypography";
import { STATUS } from "constants/fetchStatus";
import { useDispatch, useSelector } from "react-redux";
import { toggleFavPairAction } from "redux/actions/favPairs";
import SoftButton from 'components/SoftButton';
import SoftBox from "components/SoftBox";
import { useExceededTradesLimit } from "hooks/useMonthlyOperations";

// sweetalert2 components
import Swal from "sweetalert2";

// Translate
import { useTranslation } from 'react-i18next';

export default function Actions({ pair, exchange, tradePair, isFav }) {
    const { t } = useTranslation();

    const { status } = useSelector(state => state.favPairs)
    const dispatch = useDispatch()

    const isExceededTradesLimit = useExceededTradesLimit();

    const handleFavPair = () => {
        dispatch(toggleFavPairAction(pair, exchange))
    }

    const handleTrade = (event) => {
        if (isExceededTradesLimit) return Swal.fire({
            title: t("Oops..."),
            html: t("You need the premium or pro version to add more!"),
            timer: 4000,
            icon: "error",
        });
        event.preventDefault();
        tradePair(pair);
    };

    return (
        <SoftBox display="flex" alignItems="center" sx={{ cursor: "pointer", lineHeight: 0, opacity: status === STATUS.LOADING ? 0.3 : 1 }}>
            <SoftBox id="tradeButton" mr={1}>
                <SoftButton variant="gradient" size="small" color="info" onClick={handleTrade} className="trade-button">
                    <SoftTypography variant="button" color="white">
                        Trade
                    </SoftTypography>
                </SoftButton>
            </SoftBox>
            <SoftBox id="favoriteButton">
                <SoftButton variant={isFav ? "gradient" : "outlined"} color="info" onClick={handleFavPair} iconOnly>
                    <Icon>{isFav ? "star" : "star_border"}</Icon>
                </SoftButton>
            </SoftBox>
        </SoftBox>
    )
}