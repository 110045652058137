/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.2
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from "react";

import { useSelector } from "react-redux";

// @mui material components
import Container from "@mui/material/Container";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";

// Pricing page components
import Header from "layouts/Common/Pricing/components/Header";
import PricingCards from "layouts/Common/Pricing/components/PricingCards";
import ContactUs from "./components/ContactUs";

function PricingPage() {
  const pricing = useSelector((state) => state.pricing.data);
  const [isContactUs, setIsContactUs] = useState(false);
  const [tabValue, setTabValue] = useState(0);
  const [prices, setPrices] = useState(pricing.monthly);
  const [ paymentFrequency, setPaymentFrequency] = useState('monthly');

  const handleSetTabValue = (event, newValue) => {
    setTabValue(newValue);
    const currentTab = event.currentTarget.id;
    setPaymentFrequency(currentTab);
    setPrices(pricing[currentTab]);
  };

  const handleSetIsContactUs = () => setIsContactUs(!isContactUs);

  return (
    <SoftBox mt={5} mb={3}>
      <SoftBox>
        <Header
          tabValue={tabValue}
          tabHandler={handleSetTabValue}
          isContactUs={isContactUs}
        />
        <Container>
          {isContactUs ? (
            <ContactUs contactUsHandler={handleSetIsContactUs} />
          ) : (
            <PricingCards
              prices={prices}
              contactUsHandler={handleSetIsContactUs}
              paymentFrequency={paymentFrequency}
            />
          )}
        </Container>
      </SoftBox>
    </SoftBox>
  );
}

export default PricingPage;
