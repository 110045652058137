/***** We receive an action and set de data to the store *****/

import { SET_TOAST } from "constants/actionTypes";

export default (toast = { open: false, title: "", body: "", status: "", icon: "" }, action) => {
    switch (action.type) {
        case SET_TOAST:
            return {
                open: action.payload.open,
                title: action.payload.title,
                body: action.payload.body,
                status: action.payload.status,
                icon: action.payload.icon,
            }
        default:
            return toast;
    };
};