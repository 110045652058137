import { useSelector } from "react-redux";
import { useIsUserFreemium } from "utils/useUserSubscriptionStatus";

export function useExceededTradesLimit() {
    const operations = useSelector((state) => state.orders.data.trades);

    const isUserFreemium = useIsUserFreemium();

    if (!isUserFreemium || !operations || !operations.length) {
        return false;
    }

    const now = new Date();
    const startOfMonth = new Date(now.getFullYear(), now.getMonth(), 1).getTime();

    const recentOperations = operations.filter(
        (operation) =>
            operation.submittedBy === "user" &&
            operation.timestamp &&
            parseInt(operation.timestamp, 10) >= startOfMonth
    );

    const operationCount = recentOperations.length;

    return operationCount >= 30;
}
